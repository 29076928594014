define("discourse/plugins/talk-section/lib/slider", [], function () {
  "use strict";

  var Sliders = function () {
    var sliders = [];
    if ($('[data-slider="1"]').length > 0) {
      for (i = 1; i <= 10; i++) {
        slideset = $('[data-slider="' + i + '"]');
        if (slideset.length > 0) {
          var slider = new Slider(slideset);
          sliders.push(slider);
        }
      }
    }
    return sliders;
  };
  var Slider = function (slideset) {
    var waitdelay = 5000;
    var self = this;
    var activeslide = 0;
    var numslides = slideset.length;
    var hovered = false;
    var buttoncontainer;
    var buttons = [];
    var timeouts = [];
    var slidebuttonclass = 'fa-circle-thin';
    var activeslidebuttonclass = 'fa-circle';
    var disabled = false;
    slideset.hover(function () {
      self.canceltimeouts();
    }, function () {
      self.schedulenextchange();
    });
    this.updatenavbuttons = function () {
      $.each(buttons, function (index, currentbutton) {
        currentbutton.removeClass(slidebuttonclass);
        currentbutton.removeClass(activeslidebuttonclass);
        if (index === activeslide) {
          // Change the color of the buttons to the color of the active slide.
          var color = $(slideset[activeslide]).css('color');
          buttoncontainer.css('color', color);
          currentbutton.addClass(activeslidebuttonclass);
        } else {
          currentbutton.addClass(slidebuttonclass);
        }
      });
    };
    this.addnavbuttons = function () {
      buttoncontainer = $('<div class="slider-nav-buttons"></div>');
      $.each(slideset, function (i, slide) {
        var button = $('<i class="fa ' + slidebuttonclass + '"></i>');
        button.click(function () {
          self.gotoslide(i);
        });
        buttons.push(button);
      });
      buttoncontainer.append(buttons);
      buttoncontainer.hover(function () {
        self.canceltimeouts();
      }, function () {
        self.schedulenextchange();
      });
      slideset.last().after(buttoncontainer);
      self.updatenavbuttons();
    };
    this.addbuttons = function () {};
    this.getposition = function (nextslide) {
      if (nextslide === 'next') {
        nextslide = activeslide + 1;
      }
      if (nextslide >= numslides) {
        position = 0;
        activeslide = 0;
      } else {
        position = 100 / numslides * nextslide;
        position = -100 * nextslide;
        activeslide = nextslide;
      }
      return position;
    };
    this.gotoslide = function (slideid) {
      position = self.getposition(slideid);
      slideset.css('transform', 'translate3d(' + position + '%, 0px, 0px)');
      self.updatenavbuttons();
    };
    this.shownextslide = function () {
      self.gotoslide('next');
      self.schedulenextchange();
    };
    this.canceltimeouts = function () {
      if (timeouts.length > 0) {
        for (i = 0; i < timeouts.length; i++) {
          clearTimeout(timeouts[i]);
        }
      }
    };
    this.schedulenextchange = function () {
      self.canceltimeouts();
      //console.log('scheduled next change');
      timeouts.push(setTimeout(self.shownextslide, waitdelay));
    };
    //self.addnavbuttons();
    self.schedulenextchange();
    self.addbuttons();
    return this;
  };

  //var sliders = new Sliders();
});

